import EventTracker from '@/event-tracker/EventTracker'
import DataBuilder from '@/event-tracker/google/DataBuilder'
import { useGtm } from '@gtm-support/vue-gtm'

class Google extends EventTracker {
  protected dataBuilder = new DataBuilder()

  protected trackEvent(payload: any): void {
    const gtm = useGtm()
    gtm?.trackEvent(payload)
  }

  protected getEventName(): undefined {
    return undefined
  }
}

export default new Google()
