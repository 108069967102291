<script setup lang="ts">
import 'overlayscrollbars/overlayscrollbars.css'

import { useOverlayScrollbars } from 'overlayscrollbars-vue'
import { onMounted, ref } from 'vue'

const [initialize, instance] = useOverlayScrollbars({ defer: true })

const element = ref<HTMLElement>()

onMounted(() => {
  if (element.value) {
    initialize({ target: element.value })
  }
})

defineExpose({ instance: () => instance(), element })
</script>

<template>
  <div
    ref="element"
    class="hide-scrollbar overflow-y-auto"
  >
    <slot />
  </div>
</template>
