<script setup lang="ts">
import Icon from 'lilasia-icons'
import { computed, useSlots } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps<{
  href?: string
  icon?: string
  text?: string
  to?: { name: string }
}>()
const route = useRoute()

const isActive = computed(() => {
  return props.to ? route.name === props.to.name : false
})
const slots = useSlots()

const element = computed(() => {
  if (props.to) {
    return 'router-link'
  }

  if (props.href) {
    return 'a'
  }

  return 'div'
})
</script>

<template>
  <component
    :is="element"
    :to="props.to"
    :href="props.href"
    class="rounded-8 p-8 font-600 text-black-100 hover:cursor-pointer hover:bg-black-5"
  >
    <slot v-if="slots.default" />

    <div
      v-else
      class="flex items-center gap-x-12"
    >
      <Icon
        v-if="props.icon"
        :name="props.icon"
        :size="20"
        :filled="isActive"
      />
      <span>{{ props.text }}</span>
    </div>
  </component>
</template>
