<script setup lang="ts">
import { useScreenSize } from '@/composables/screen-size'
import useInquiryStore from '@/stores/inquiry'
import useSearchStore, { SearchState } from '@/stores/search'
import useSearchSuggestionStore from '@/stores/search-suggestion'
import { useDebounceFn } from '@vueuse/core'
import Icon from 'lilasia-icons'
import { AppSection, ButtonLink, useDialog } from 'lilasia-ui'
import type { UseOverlayScrollbarsInstance } from 'overlayscrollbars-vue'
import { storeToRefs } from 'pinia'
import { computed, ref, useId, watch } from 'vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import FadeTransition from '../Transitions/FadeTransition.vue'
import MakeInquiryModal from '../common/MakeInquiryModal.vue'
import OverlayScrollbar from '../common/OverlayScrollbar.vue'
import SearchSuggestions from './SearchSuggestions.vue'

const props = defineProps<{
  placeholder?: string
}>()

const showPopover = defineModel<boolean>('suggestions')

const { isDesktop, isMobile } = useScreenSize()
const { showDialog } = useDialog()
const route = useRoute()
const router = useRouter()
const searchStore = useSearchStore()
const searchSuggestionStore = useSearchSuggestionStore()
const inquiryStore = useInquiryStore()

const { keyword, state } = storeToRefs(searchStore)
const { activeIndex, maxIndex } = storeToRefs(searchSuggestionStore)
const searchInput = ref<HTMLInputElement>()
const popover = ref<HTMLElement>()
const popoverPanel = ref<
  HTMLElement & { element: HTMLElement; instance: UseOverlayScrollbarsInstance }
>()

const id = useId()

const searchPlaceholder = computed(() => {
  if (!props.placeholder) {
    return isMobile ? 'Search…' : 'What are you looking for…'
  }

  return props.placeholder
})

watch(showPopover, (newShowPopover) => {
  if (!newShowPopover) {
    searchInput.value?.blur()
  }
})

onBeforeRouteUpdate(() => {
  searchInput.value?.blur()
  showPopover.value = false
})

const scrollToActiveIndex = () => {
  const element = document.querySelector(
    `[data-lilasia-search="search-${activeIndex.value}"]`
  ) as HTMLElement | null

  const osInstance = popoverPanel.value?.instance()

  if (element && popoverPanel.value?.element && osInstance) {
    const popover = popoverPanel.value.element
    const popoverHeight = popover.clientHeight

    const elementTop = element.getBoundingClientRect().top - popover.getBoundingClientRect().top

    if (elementTop < 0 || elementTop + element.offsetHeight > popoverHeight) {
      osInstance.elements().viewport.scrollTop +=
        elementTop - popoverHeight / 2 + element.offsetHeight / 2
    }
  }
}

const handlePopoverFocusIn = () => {
  popover.value?.classList.add('focused')
}

const handlePopoverFocusOut = () => {
  popover.value?.classList.remove('focused')

  setTimeout(() => {
    if (!popover.value?.classList.contains('focused')) {
      showPopover.value = false
    }
  }, 150)
}

const handlePopoverArrowUp = () => {
  if (activeIndex.value === 0) {
    activeIndex.value = maxIndex.value
    scrollToActiveIndex()
    return
  }

  activeIndex.value -= 1
  scrollToActiveIndex()
}

const handlePopoverArrowDown = () => {
  if (activeIndex.value === maxIndex.value) {
    activeIndex.value = 0
    scrollToActiveIndex()
    return
  }

  activeIndex.value += 1
  scrollToActiveIndex()
}

const handlePopoverEscape = () => {
  if (activeIndex.value === 0) {
    showPopover.value = false
    return
  }

  activeIndex.value = 0
}

const handlePopoverEnter = () => {
  const element = document.querySelector(
    `[data-lilasia-search="search-${activeIndex.value}"]`
  ) as HTMLElement | null

  if (!element) {
    return
  }

  if (element.dataset.lilasiaInquiry) {
    inquiryStore.url = element.dataset.lilasiaInquiry
    showDialog(MakeInquiryModal)
    showPopover.value = false
    return
  }

  if (!element.dataset.lilasiaSearchTo) {
    return
  }

  const searchTo = JSON.parse(element.dataset.lilasiaSearchTo)

  router.push(searchTo)
  showPopover.value = false
}

const handleSearchFocusIn = () => {
  if (!isDesktop) {
    router.push({ name: 'search.suggestions', query: { query: route.query.query } })
    return
  }

  showPopover.value = true
}

const handleSearchFocusOut = () => {
  setTimeout(() => {
    if (popover.value?.classList.contains('focused')) {
      return
    }

    showPopover.value = false
  }, 150)
}

const handleSearch = useDebounceFn(() => {
  if (activeIndex.value > -1) {
    return
  }

  const searchKeyword = keyword.value.trim()

  if (searchKeyword === '') {
    return
  }

  router.push({
    name: 'search',
    query: { query: searchKeyword }
  })
})

const showInquiryModal = () => {
  showDialog(MakeInquiryModal)
}
</script>

<template>
  <div
    ref="popover"
    class="relative w-full outline-none"
    tabindex="0"
    @focusin="handlePopoverFocusIn"
    @focusout="handlePopoverFocusOut"
    @keydown.up.prevent="handlePopoverArrowUp"
    @keydown.down.prevent="handlePopoverArrowDown"
    @keyup.esc.prevent="handlePopoverEscape"
    @keyup.enter.prevent="handlePopoverEnter"
  >
    <div class="relative z-40">
      <label
        :for="id"
        class="flex w-full items-center justify-center gap-4 rounded-24 border border-black-20 bg-white p-8 text-16 outline-none"
      >
        <button
          class="btn px-4 text-black-80 outline-none"
          type="button"
          @click="handleSearch"
        >
          <Icon
            name="search"
            size="24"
          />
        </button>

        <div class="flex-fill rounded-pill relative w-full">
          <input
            :id="id"
            ref="searchInput"
            v-model="keyword"
            type="search"
            autocomplete="off"
            class="w-full border-0 bg-white px-4 text-black-80 outline-none placeholder:text-black-60"
            :placeholder="searchPlaceholder"
            @focusin="handleSearchFocusIn"
            @focusout="handleSearchFocusOut"
            @keyup.up.prevent="() => false"
            @keyup.down.prevent="() => false"
            @keydown.enter.prevent="handleSearch"
          />
        </div>
      </label>
    </div>

    <FadeTransition group>
      <!-- Popover Panel -->
      <div
        v-if="showPopover"
        class="absolute z-40 mt-4 w-full"
      >
        <!-- Popover Content -->
        <OverlayScrollbar
          ref="popoverPanel"
          :class="{
            'max-h-[408px] w-full rounded-t-16 bg-white': true,
            'rounded-b-16': state === SearchState.PasteLink
          }"
        >
          <SearchSuggestions />
        </OverlayScrollbar>

        <!-- Popover Footer -->
        <footer
          v-if="state !== SearchState.PasteLink"
          class="bg-black-5 lg:rounded-b-16"
        >
          <AppSection
            fluid
            no-gap
          >
            <div
              :class="{
                'container mx-auto': !isDesktop
              }"
            >
              <div class="flex flex-wrap justify-center gap-0 py-24 xs:py-16 lg:py-16">
                <span>Can't find what you need?&nbsp;</span>
                <ButtonLink
                  size="lg"
                  text="Make an inquiry here."
                  class="outline-black-40"
                  @click="showInquiryModal"
                />
              </div>
            </div>
          </AppSection>
        </footer>
      </div>

      <!-- Backdrop -->
      <div
        v-if="showPopover"
        class="fixed inset-0 z-30 bg-black/40"
        @click="showPopover = false"
      ></div>
    </FadeTransition>
  </div>
</template>
