import useAppStore from '@/stores/app'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const useMerchantStore = defineStore('merchant', () => {
  const appStore = useAppStore()

  const shop = ref<Shop>()
  const selectedProduct = ref<Product | null>(null)
  const refetch = ref(false)
  const refetchProduct = ref(false)
  const fluidSection = ref(false)

  const switchToDesktop = useLocalStorage(
    `${appStore.storagePrefix}_merchant_switch_to_desktop`,
    () => {
      return 0
    }
  )

  const shouldSwitchToDesktop = () => {
    const TTL = 60 * 60 * 2
    const now = Math.floor(Date.now() / 1000)
    const expired = switchToDesktop.value < now - TTL

    if (expired) {
      switchToDesktop.value = now
      return true
    }

    return false
  }

  return {
    shop,
    selectedProduct,
    refetch,
    refetchProduct,
    fluidSection,
    shouldSwitchToDesktop
  }
})

export default useMerchantStore
