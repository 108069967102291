<script setup lang="ts">
import { useImage } from '@vueuse/core'
import { AppSkeleton } from 'lilasia-ui'
import { computed } from 'vue'

const props = defineProps<{
  alt: string
  class?: any
  height?: number
  rounded?: 8 | 16
  src: string
  width: number
}>()

const { isLoading, error } = useImage({ src: props.src })

const classes = computed(() => {
  return [
    props.class,
    {
      'rounded-8': props.rounded === 8,
      'rounded-16': props.rounded === 16
    }
  ]
})
</script>

<template>
  <AppSkeleton
    v-if="isLoading"
    :class="props.class"
  />
  <img
    v-else-if="error"
    src="/images/common/default.svg"
    :alt="alt"
    :width="width"
    :height="height"
    :class="classes"
  />
  <img
    v-else
    :src="src"
    :alt="alt"
    :width="width"
    :height="height"
    :class="classes"
  />
</template>
