import { useI18n } from 'vue-i18n'

export const useMenu = () => {
  const { t } = useI18n()

  const accountMenu = [
    {
      label: t('profile'),
      to: { name: 'account.edit-profile' },
      icon: 'person'
    },

    {
      label: t('password'),
      to: { name: 'account.change-password' },
      icon: 'lock'
    },
    {
      label: t('order_history'),
      to: { name: 'account.order-history' },
      icon: 'local_mall'
    },
    {
      label: t('address'),
      to: { name: 'account.addresses' },
      icon: 'location_on'
    },
    {
      label: t('wishlist'),
      to: { name: 'account.wishlist' },
      icon: 'favorite'
    },
    {
      label: t('browsing_history'),
      to: { name: 'account.browse-history' },
      icon: 'schedule'
    }
  ]

  const businessSolutionMenu = [
    {
      label: t('lilasia_for_merchant'),
      to: { name: 'merchant' },
      icon: 'store'
    },
    {
      label: t('lilasia_commodity_sourcing'),
      to: { name: 'wholesale' },
      icon: 'pallet'
    }
  ]

  const companyMenu = [
    {
      label: t('about_us'),
      to: { name: 'about' },
      icon: 'groups'
    },
    {
      label: t('contact_us'),
      to: { name: 'contact' },
      icon: 'call'
    },
    {
      label: t('blog'),
      href: 'https://blog.lilasia.id/',
      icon: 'rss_feed'
    }
  ]

  const legalMenu = [
    {
      label: t('terms_and_conditions'),
      to: { name: 'terms-condition' },
      icon: 'contract'
    },
    {
      label: t('privacy_policy'),
      to: { name: 'privacy-policy' },
      icon: 'security'
    }
  ]

  const merchantMenu = [
    {
      label: t('manage_data', { data: t('products') }),
      to: { name: 'merchant.manage-products' },
      icon: 'package_2'
    },
    {
      label: t('shop_settings'),
      to: { name: 'merchant.shop-settings' },
      icon: 'storefront'
    }
  ]

  return {
    accountMenu,
    businessSolutionMenu,
    companyMenu,
    legalMenu,
    merchantMenu
  }
}
