import useAppStore from '@/stores/app'

export const useNumber = () => {
  const appStore = useAppStore()

  const convertToInteger = (string: string) => {
    const match = string.match(/\d+/)
    return match ? parseInt(match[0], 10) : NaN
  }

  const formatNumber = (number: number = 0) => {
    return new Intl.NumberFormat(appStore.selectedRate.locale).format(number)
  }

  const formatSold = (sold: number) => {
    const formattedNumber = formatNumber(sold)
    const plusText = sold >= 30

    return `${formattedNumber}${plusText ? '+' : ''}`
  }

  return {
    convertToInteger,
    formatNumber,
    formatSold
  }
}
