import FetchError from '@/FetchError'
import useAuthStore from '@/stores/auth'
import useFetchStore from '@/stores/fetch'
import { useFetch as useVueFetch } from '@vueuse/core'

export const useFetch = () => {
  const fetch = <T>(url: string, fetchOptions: RequestInit = {}) => {
    const authStore = useAuthStore()
    const fetchStore = useFetchStore()

    return useVueFetch<T>(`${import.meta.env.VITE_APP_URL}/api/${url}`, {
      beforeFetch: ({ options }) => {
        options.headers = {
          Accept: 'application/json',
          Authorization: `Bearer ${authStore.token}`,
          ...options.headers
        }

        return { options: { ...options, ...fetchOptions } }
      },

      updateDataOnError: true,

      onFetchError(context) {
        if (context.error) {
          if (context.data && context.data.message) {
            context.error = new FetchError(
              context.data.message,
              context.data.errors,
              context.response?.status
            )
          }

          fetchStore.error = {
            message: context.data.message,
            errors: context.data.errors,
            code: context.response?.status
          }
        }

        return context
      }
    })
  }

  return { fetch }
}
