import Api from '@/api/Api'

class Inquiry extends Api {
  public async make<T>(request: MakeInquiryRequest) {
    const { data, error, response } = await this.fetch<T>('make-inquiry').post(request).json<T>()

    return this.handleResponse<T>(data.value, error.value, response.value?.status)
  }
}

export default new Inquiry()
