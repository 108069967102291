import type { App } from 'vue'

declare global {
  interface Window {
    fbq: (action: string, event: string, data?: Record<string, unknown>) => void
    doSomething: () => void
  }
}

type MetaPixelOptions = {
  pixelId: string
}

export default {
  install(app: App, options: MetaPixelOptions) {
    // Create the Meta Pixel script tag
    if (!window.fbq) {
      const script = document.createElement('script')
      script.innerHTML = `
        !(function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;
        s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}
        )(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${options.pixelId}');
        fbq('track', 'PageView');`

      // Add script to the document head
      document.head.appendChild(script)

      // Add noscript fallback for users with JavaScript disabled
      const noscript = document.createElement('noscript')
      noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${options.pixelId}&ev=PageView&noscript=1"/>`
      document.body.appendChild(noscript)
    }
  }
}

export const trackEvent = (event: string, data?: Record<string, unknown>) => {
  if (!window.fbq) {
    console.warn('Meta Pixel not initialized')
    return
  }

  data ? window.fbq('track', event, data) : window.fbq('track', event)
}
