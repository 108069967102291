<script setup lang="ts">
import useAppStore from '@/stores/app'
import useAuthStore from '@/stores/auth'
import { AppButton, AppDropdown, AppNavbar, DropdownItem, DropdownItemGroup } from 'lilasia-ui'
import { useI18n } from 'vue-i18n'
import MerchantMobileMenu from './MerchantMobileMenu.vue'

const { t } = useI18n()
const appStore = useAppStore()
const authStore = useAuthStore()

const logout = () => {
  authStore.logout()
}
</script>

<template>
  <AppNavbar
    class="flex h-56 items-center bg-white shadow md:h-[72px]"
    sticky
  >
    <nav class="flex items-center justify-between gap-8 md:gap-32">
      <div class="flex items-center justify-start gap-32 md:min-w-[103px] lg:min-w-[110px]">
        <div class="flex items-center gap-16">
          <RouterLink :to="{ name: 'home' }">
            <!-- Desktop/Tablet Logo -->
            <img
              class="hidden h-24 md:block"
              src="/images/common/lilasia-logo-black.svg"
              :alt="appStore.name"
              width="100"
              height="25.81"
            />

            <!-- Mobile Logo -->
            <img
              class="size-32 md:hidden"
              src="/images/common/lilasia-logo.svg"
              :alt="appStore.name"
              width="32"
              height="32"
            />
          </RouterLink>

          <!-- Merchant Centre Label -->
          <div class="border-l border-l-black-10 pl-16 text-24 font-600 leading-32 text-black-60">
            {{ t('merchant_centre') }}
          </div>
        </div>
      </div>

      <div class="flex items-center justify-end sm:gap-12 md:gap-24">
        <!-- User and Login Button -->
        <AppDropdown
          class="hidden lg:block"
          menu-position="right"
        >
          <template #button>
            <AppButton
              color="light"
              :text="String(authStore.user.name)"
              icon-right="keyboard_arrow_down"
            />
          </template>
          <DropdownItemGroup>
            <DropdownItem
              :to="{ name: 'home' }"
              icon="undo"
              text="Back to Lilasia"
            />
            <DropdownItem
              icon="logout"
              text="Logout"
              active-color="red"
              @click="logout"
            />
          </DropdownItemGroup>
        </AppDropdown>

        <!-- Hamburger Button -->
        <MerchantMobileMenu />
      </div>
    </nav>
  </AppNavbar>
</template>
