<script setup lang="ts">
import Inquiry from '@/api/Inquiry'
import { useTracker } from '@/composables/tracker'
import { useValidator } from '@/composables/validator'
import useAppStore from '@/stores/app'
import useAuthStore from '@/stores/auth'
import useInquiryStore from '@/stores/inquiry'
import {
  AppBlock,
  AppButton,
  AppDivider,
  FormInput,
  FormTextarea,
  useDialog,
  useToast
} from 'lilasia-ui'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { z } from 'zod'
import CloseButton from './CloseButton.vue'

const { trackInitiateInquiry, trackInquirySubmission } = useTracker()
const { t } = useI18n()
const { toastSuccess, toastError } = useToast()
const { hideDialog } = useDialog()

const appStore = useAppStore()
const authStore = useAuthStore()
const inquiryStore = useInquiryStore()

const loading = ref(false)

const schema = z.object({
  name: z.string(),
  company: z.string().optional(),
  email: z.string().email('Invalid email').min(1, 'Email is required'),
  phone: z.string(),
  currency: z.string(),
  message: z.string().optional(),
  items: z.array(
    z.object({
      product_name: z.string(),
      expected_price: z.number().min(1),
      url: z.string().optional()
    })
  )
})

const { formData: form, formError: error, validate } = useValidator(schema)

onMounted(() => {
  const authenticated = authStore.isAuthenticated
  form.value.name = authenticated ? (authStore.user.name ? authStore.user.name : '') : ''
  form.value.email = authenticated ? (authStore.user.email ? authStore.user.email : '') : ''
  form.value.phone = authenticated
    ? authStore.user.phone_number
      ? authStore.user.phone_number
      : ''
    : ''
  form.value.currency = appStore.currency.selected
  form.value.items = [
    {
      product_name: '',
      expected_price: appStore.currency.selected === 'IDR' ? 10000 : 10,
      url: inquiryStore.url
    }
  ]

  trackInitiateInquiry()
})

const addMoreItem = () => {
  form.value.items.push({
    product_name: '',
    expected_price: appStore.currency.selected === 'IDR' ? 10000 : 10,
    url: ''
  })
}

const deleteItem = (index: number) => {
  form.value.items.splice(index, 1)
}

const submit = async () => {
  if (validate()) {
    loading.value = true

    try {
      const { message } = await Inquiry.make<{ message: string }>(form.value as MakeInquiryRequest)
      trackInquirySubmission()
      toastSuccess(message)
      hideDialog()
      Object.assign(form.value, {})
    } catch (error) {
      const fetchError = error as FetchError
      toastError(fetchError.message)
    } finally {
      loading.value = false
    }
  }
}
</script>

<template>
  <AppBlock
    class="w-full md:max-w-[432px] lg:max-w-[600px]"
    :rounded="16"
  >
    <form @submit.prevent="submit">
      <div class="p-24">
        <div class="flex flex-col gap-16">
          <div class="flex flex-col items-center gap-8">
            <div class="flex w-full">
              <div class="leading-26 w-full text-center text-20 font-600">
                {{ t('make_inquiry') }}
              </div>

              <div class="ml-auto pt-4">
                <CloseButton
                  size="24"
                  @click="hideDialog()"
                />
              </div>
            </div>

            <div class="text-start text-14 leading-20 text-black-60">
              {{ t('make_inquiry_description') }}
            </div>
          </div>

          <AppDivider v-if="!authStore.isAuthenticated || !authStore.user.phone_number" />

          <FormInput
            v-if="!authStore.isAuthenticated"
            id="full-name"
            v-model="form.name"
            :label="t('full_name')"
            :error="error.name"
            placeholder="Enter your full name"
            required
          />
          <FormInput
            v-if="!authStore.isAuthenticated"
            id="company"
            v-model="form.company"
            :error="error.company"
            :label="t('company')"
            placeholder="Enter your company"
          />
          <FormInput
            v-if="!authStore.isAuthenticated"
            id="email"
            v-model="form.email"
            :error="error.email"
            type="email"
            :label="t('email_address')"
            placeholder="Enter your email address"
            required
          />
          <FormInput
            v-if="!authStore.isAuthenticated && !authStore.user.phone_number"
            id="phone-number"
            v-model="form.phone"
            :error="error.phone"
            :label="t('phone_number')"
            placeholder="Enter your phone number"
            required
          />

          <AppDivider />

          <FormTextarea
            id="messages"
            v-model="form.message"
            :error="error.message"
            :label="t('messages')"
            placeholder="Enter your message here"
          />

          <AppDivider />

          <template
            v-for="(item, index) in form.items"
            :key="index"
          >
            <div class="text-center text-16 font-600 leading-20 text-black-60">
              {{ `${t('product')} ${index + 1}` }}
            </div>
            <div class="relative">
              <FormInput
                :id="`product-name-${index + 1}`"
                v-model="item.product_name"
                :label="t('product_name')"
                placeholder="Enter your product name"
                required
              />
              <div
                v-if="index !== 0"
                class="absolute right-[0] top-[0]"
              >
                <button
                  type="button"
                  class="text-14 text-red-100 underline"
                  @click="deleteItem(index)"
                >
                  {{ t('delete') }}
                </button>
              </div>
            </div>
            <FormInput
              :id="`expected-price-${index + 1}`"
              v-model="item.expected_price"
              type="number"
              :error="error.items"
              :label="t('expected_price')"
              placeholder="Enter your expected price"
              required
            />
            <FormInput
              :id="`url-${index + 1}`"
              v-model="item.url"
              type="url"
              :label="t('url')"
              placeholder="Enter your URL"
            />

            <AppDivider v-if="index + 1 !== form.items.length" />
          </template>
        </div>
      </div>

      <div class="rounded-b-16 bg-black-5 p-24">
        <div class="flex flex-col justify-center gap-16 md:flex-row md:justify-end">
          <AppButton
            type="button"
            :text="t('add_more_product')"
            @click="addMoreItem"
          />
          <AppButton
            type="submit"
            color="blue"
            :text="t('submit_inquiry')"
            :loading="loading"
          />
        </div>
      </div>
    </form>
  </AppBlock>
</template>
