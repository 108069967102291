<script setup lang="ts">
import useAppStore from '@/stores/app'
import { AppBlock, AppButton, FormRadioGroup, useDialog } from 'lilasia-ui'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { hideDialog } = useDialog()
const appStore = useAppStore()

const selectedCurrency = ref()

onMounted(() => {
  selectedCurrency.value = appStore.exchangeRates.find(
    (rate) => rate.currency === appStore.currency.selected
  )
})

const handleChange = (updatedValue: Rate) => {
  const rate = appStore.exchangeRates.find((rate) => rate.currency === updatedValue.currency)
  if (rate) {
    appStore.currency.selected = rate.currency
    hideDialog()
  }
}
</script>

<template>
  <AppBlock class="w-full md:!w-[432px]">
    <div class="p-24">
      <div class="flex flex-col gap-16">
        <div class="relative">
          <div class="text-start text-20 font-600 leading-25">
            {{ t('select_currency') }}
          </div>
          <div class="absolute right-[-15px] top-[50%] translate-y-[-50%]">
            <AppButton
              color="light"
              icon="close"
              round
              @click="hideDialog"
            />
          </div>
        </div>
        <FormRadioGroup
          id="currency"
          v-model="selectedCurrency"
          :options="appStore.exchangeRates"
          @update:model-value="handleChange"
        >
          <template #option-label="{ option }">
            <div class="mr-16 flex items-center gap-8">
              <img
                :src="option.flag_url"
                :alt="option.label"
                width="24"
                height="24"
              />
              <div class="text-16 font-600 leading-20">
                {{ `${option.label} (${option.currency})` }}
              </div>
            </div>
          </template>
        </FormRadioGroup>
      </div>
    </div>
  </AppBlock>
</template>
