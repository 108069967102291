import GoogleDataBuilder from '@/event-tracker/google/DataBuilder'
import MetaDataBuilder from '@/event-tracker/meta/DataBuilder'

abstract class EventTracker {
  public addPaymentInfo = (): void => {
    throw new Error('Method not implemented.')
  }

  public addShippingInfo = (): void => {
    throw new Error('Method not implemented.')
  }

  public addToCart = (product: Product, quantity: number, guest = false): void => {
    this.trackEvent(
      this.dataBuilder.addToCartData(product, quantity, guest),
      this.getEventName('addToCart')
    )
  }

  public addToWishlist = (product: Product): void => {
    this.trackEvent(this.dataBuilder.addToWishlistData(product), this.getEventName('addToWishlist'))
  }

  public beginCheckout = (products: Product[], value: number): void => {
    this.trackEvent(
      this.dataBuilder.beginCheckoutData(products, value),
      this.getEventName('beginCheckout')
    )
  }

  public login = (): void => {
    throw new Error('Method not implemented.')
  }

  public purchase = (order: Order): void => {
    this.trackEvent(this.dataBuilder.purchaseData(order), this.getEventName('purchase'))
  }

  public refund = (): void => {
    throw new Error('Method not implemented.')
  }

  public removeFromCart = (products: Product[], value: number): void => {
    this.trackEvent(
      this.dataBuilder.removeFromCartData(products, value),
      this.getEventName('removeFromCart')
    )
  }

  public search = (keyword: string): void => {
    this.trackEvent(this.dataBuilder.searchData(keyword), this.getEventName('search'))
  }

  public selectContent = (): void => {
    throw new Error('Method not implemented.')
  }

  public selectItem = (): void => {
    throw new Error('Method not implemented.')
  }

  public share = (product: Product): void => {
    this.trackEvent(this.dataBuilder.shareData(product), this.getEventName('share'))
  }

  public signUp = (): void => {
    this.trackEvent(this.dataBuilder.signUpData(), this.getEventName('signUp'))
  }

  public viewCart = (): void => {
    throw new Error('Method not implemented.')
  }

  public viewItem = (product: Product): void => {
    this.trackEvent(this.dataBuilder.viewItemData(product), this.getEventName('viewItem'))
  }

  public trackContact = (): void => {
    this.trackEvent(this.dataBuilder.contactData(), this.getEventName('contact'))
  }

  public trackInitiateInquiry = (): void => {
    this.trackEvent(this.dataBuilder.initiateInquiryData(), this.getEventName('initiateInquiry'))
  }

  public trackInquirySubmission = (): void => {
    this.trackEvent(
      this.dataBuilder.inquirySubmissionData(),
      this.getEventName('inquirySubmission')
    )
  }

  public trackShare = (product: Product): void => {
    this.trackEvent(this.dataBuilder.shareData(product), this.getEventName('share'))
  }

  protected abstract dataBuilder: GoogleDataBuilder | MetaDataBuilder

  protected abstract getEventName(event?: string): string | undefined

  protected abstract trackEvent(payload: any, event?: string): void
}

export default EventTracker
