import Role from '@/constants/role'
import useAppStore from '@/stores/app'
import { useLocalStorage } from '@vueuse/core'
import { useDialog } from 'lilasia-ui'
import { defineStore } from 'pinia'
import { computed, defineAsyncComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import useCartStore from './cart'

const useAuthStore = defineStore('auth', () => {
  const { showDialog } = useDialog()
  const router = useRouter()
  const appStore = useAppStore()

  const token = useLocalStorage(`${appStore.storagePrefix}token`, '')
  const user = useLocalStorage(`${appStore.storagePrefix}user`, <User>{})
  const intent = ref<AuthIntent>(AuthIntent.Login)
  const modalVisible = ref(false)
  const showCompleteYourProfileModal = ref(false)

  const isAuthenticated = computed(() => !!token.value)

  const isGuest = computed(() => !isAuthenticated.value)

  const isCustomer = computed(
    () => isAuthenticated.value && user.value.roles.includes(Role.CUSTOMER)
  )

  const isShopOwner = computed(
    () => isAuthenticated.value && user.value.roles.includes(Role.SHOP_OWNER)
  )

  const isMerchant = computed(() => isShopOwner.value)

  const incompleteUserProfile = computed(() => {
    return !user.value.dial_code || !user.value.phone_number || !user.value.country_id
  })

  const can = (permission: string) => {
    if (user && user.value.permissions) {
      return Boolean(user.value.permissions.includes(permission))
    }

    return false
  }

  const logout = () => {
    const cartStore = useCartStore()

    token.value = ''
    user.value = <User>{}
    cartStore.cart = cartStore.defaultCart

    router.push({ name: 'home' })
  }

  const showAuthModal = (authIntent: AuthIntent = AuthIntent.Login) => {
    intent.value = authIntent
    showDialog(
      defineAsyncComponent(() => import('@/components/common/auth/AuthModal.vue')),
      { position: 'middle' }
    )
  }

  const switchToSignIn = () => {
    intent.value = AuthIntent.Login
  }

  const switchToSignUp = () => {
    intent.value = AuthIntent.Signup
  }

  return {
    switchToSignIn,
    switchToSignUp,
    token,
    user,
    intent,
    modalVisible,
    showCompleteYourProfileModal,
    isAuthenticated,
    isGuest,
    isCustomer,
    isShopOwner,
    isMerchant,
    incompleteUserProfile,
    can,
    logout,
    showAuthModal
  }
})

export enum AuthIntent {
  Login = 'login',
  Signup = 'signup',
  MerchantSignup = 'merchant-signup'
}
export default useAuthStore
