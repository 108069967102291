import Api from '@/api/Api'

class Merchant extends Api {
  protected resource: string = 'merchant'

  public async settings<T>(request: MerchantSettingsRequest) {
    const { data, error, response } = await this.fetch<T>(`${this.resource}/settings`)
      .post(request)
      .json<T>()

    return this.handleResponse(data.value, error.value, response.value?.status)
  }

  public async uploadLogo<T>(request: FormData) {
    const { data, error, response } = await this.fetch<T>(`${this.resource}/logo`)
      .post(request)
      .formData()
      .json<T>()

    return this.handleResponse(data.value, error.value, response.value?.status)
  }

  public async deleteLogo<T>() {
    const { data, error, response } = await this.fetch<T>(`${this.resource}/logo`)
      .delete()
      .json<T>()

    return this.handleResponse(data.value, error.value, response.value?.status)
  }

  public async uploadBanner<T>(request: FormData) {
    const { data, error, response } = await this.fetch<T>(`${this.resource}/banner`)
      .post(request)
      .formData()
      .json<T>()

    return this.handleResponse(data.value, error.value, response.value?.status)
  }

  public async deleteBanner<T>() {
    const { data, error, response } = await this.fetch<T>(`${this.resource}/banner`)
      .delete()
      .json<T>()

    return this.handleResponse(data.value, error.value, response.value?.status)
  }

  public async applyAsMerchant<T>(request: FormData) {
    const { data, error, response } = await this.fetch(`${this.resource}/apply-as-merchant`)
      .post(request)
      .json<T>()

    return this.handleResponse<T>(data.value, error.value, response.value?.status)
  }
}

export default new Merchant()
