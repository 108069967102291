import EventTracker from '@/event-tracker/EventTracker'
import DataBuilder from '@/event-tracker/meta/DataBuilder'
import metaEvent from '@/event-tracker/meta/event'
import { trackEvent } from '@/plugins/meta-pixel'

class Meta extends EventTracker {
  protected dataBuilder = new DataBuilder()

  protected getEventName(event: string): string {
    const eventMap: { [key: string]: string } = {
      addToCart: metaEvent.ADD_TO_CART,
      addToWishlist: metaEvent.ADD_TO_WISHLIST,
      beginCheckout: metaEvent.INITIATE_CHECKOUT,
      purchase: metaEvent.PURCHASE,
      search: metaEvent.SEARCH,
      signUp: metaEvent.COMPLETE_REGISTRATION,
      viewItem: metaEvent.VIEW_CONTENT
    }

    return eventMap[event]
  }

  protected trackEvent(payload: any, event: string): void {
    trackEvent(event, payload)
  }
}

export default new Meta()
