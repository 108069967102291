const event = {
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_TO_CART: 'add_to_cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEGIN_CHECKOUT: 'begin_checkout',
  CLOSE_CONVERT_LEAD: 'close_convert_lead',
  CLOSE_UNCONVERT_LEAD: 'close_unconvert_lead',
  CONTACT: 'contact',
  DISQUALIFY_LEAD: 'disqualify_lead',
  EARN_VIRTUAL_CURRENCY: 'earn_virtual_currency',
  GENERATE_LEAD: 'generate_lead',
  GUEST_ADD_TO_CART: 'guest_add_to_cart',
  INITIATE_INQUIRY: 'initiate_inquiry',
  INQUIRY_SUBMISSION: 'inquiry_submission',
  JOIN_GROUP: 'join_group',
  LEVEL_END: 'level_end',
  LEVEL_START: 'level_start',
  LEVEL_UP: 'level_up',
  LOGIN: 'login',
  POST_SCORE: 'post_score',
  PURCHASE: 'purchase',
  QUALIFY_LEAD: 'qualify_lead',
  REFUND: 'refund',
  REMOVE_FROM_CART: 'remove_from_cart',
  SEARCH: 'search',
  SELECT_CONTENT: 'select_content',
  SELECT_ITEM: 'select_item',
  SELECT_PROMOTION: 'select_promotion',
  SHARE: 'share',
  SIGN_UP: 'sign_up',
  SPEND_VIRTUAL_CURRENCY: 'spend_virtual_currency',
  TUTORIAL_BEGIN: 'tutorial_begin',
  TUTORIAL_COMPLETE: 'tutorial_complete',
  UNLOCK_ACHIEVEMENT: 'unlock_achievement',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_PROMOTION: 'view_promotion',
  WORKING_LEAD: 'working_lead'
}

export default event
