<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { RouterView } from 'vue-router'

const AppObserver = defineAsyncComponent(() => import('@/observers/AppObserver.vue'))
const ErrorObserver = defineAsyncComponent(() => import('@/observers/ErrorObserver.vue'))
const AuthObserver = defineAsyncComponent(() => import('@/observers/AuthObserver.vue'))
const MyCartObserver = defineAsyncComponent(() => import('@/observers/MyCartObserver.vue'))
const WishlistObserver = defineAsyncComponent(() => import('@/observers/WishlistObserver.vue'))
</script>

<template>
  <RouterView />
  <AppObserver />
  <ErrorObserver />
  <AuthObserver />
  <MyCartObserver />
  <WishlistObserver />
</template>
