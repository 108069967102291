<script setup lang="ts">
import SelectCurrency from '@/components/common/SelectCurrency.vue'
import ListGroup from '@/components/common/list-group/ListGroup.vue'
import ListGroupItem from '@/components/common/list-group/ListGroupItem.vue'
import ListGroupSection from '@/components/common/list-group/ListGroupSection.vue'
import ListGroupTitle from '@/components/common/list-group/ListGroupTitle.vue'
import { useMenu } from '@/composables/menu'
import useAppStore from '@/stores/app'
import useAuthStore, { AuthIntent } from '@/stores/auth'
import Icon from 'lilasia-icons'
import { AppBlock, AppButton, AppDivider, AppDrawer, useDialog } from 'lilasia-ui'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate } from 'vue-router'
import CloseButton from './CloseButton.vue'

const { accountMenu, businessSolutionMenu, companyMenu, legalMenu, merchantMenu } = useMenu()
const { showDialog } = useDialog()
const { t } = useI18n()

const appStore = useAppStore()
const authStore = useAuthStore()

const { showMobileMenu } = storeToRefs(appStore)

onBeforeRouteUpdate(() => {
  showMobileMenu.value = false
})

/* @todo move to currency store */
const openCurrencyModal = () => {
  showDialog(SelectCurrency, { position: 'top' })
  showMobileMenu.value = false
}

const showMerchantRegistration = () => {
  authStore.showAuthModal(AuthIntent.MerchantSignup)
  showMobileMenu.value = false
}

const login = () => {
  authStore.showAuthModal()
  showMobileMenu.value = false
}
</script>

<template>
  <AppDrawer
    v-model="showMobileMenu"
    position="right"
    class="w-[350px]"
    backdrop
  >
    <AppBlock class="flex h-dvh flex-col rounded-[0]">
      <div class="p-16">
        <div class="relative flex-1 text-center font-600 text-black-100">
          <div class="absolute top-1/2 flex -translate-y-1/2 items-center">
            <CloseButton
              size="24"
              @click="showMobileMenu = false"
            />
          </div>
          <div class="text-20 leading-28">
            {{ t('menu') }}
          </div>
        </div>
      </div>

      <div class="flex h-dvh flex-col justify-between gap-y-16 overflow-y-auto p-16">
        <div>
          <ListGroup>
            <ListGroupSection>
              <ListGroupTitle :text="t('currency')" />
              <ListGroupItem
                class="flex items-center justify-between"
                @click="openCurrencyModal"
              >
                <div class="flex items-center gap-x-16">
                  <img
                    :src="String(appStore.selectedRate.flag_url).replace('flag', 'icon')"
                    :alt="`selected-${appStore.selectedRate.currency}-icon`"
                    width="18"
                  />
                  <span>
                    {{ `${appStore.selectedRate.label} (${appStore.selectedRate.currency})` }}
                  </span>
                </div>
                <Icon
                  name="chevron_right"
                  size="20"
                />
              </ListGroupItem>
            </ListGroupSection>
            <template v-if="authStore.isAuthenticated">
              <AppDivider />
              <ListGroupSection>
                <ListGroupTitle :text="t('account')" />
                <ListGroupItem
                  v-for="(menu, index) in accountMenu"
                  :key="index"
                  :to="menu.to"
                  :icon="menu.icon"
                  :text="menu.label"
                  active-class="text-blue-100"
                />
              </ListGroupSection>
            </template>
            <template v-if="authStore.isMerchant">
              <AppDivider />
              <ListGroupSection>
                <ListGroupTitle :text="t('merchant_centre')" />
                <ListGroupItem
                  v-for="(menu, index) in merchantMenu"
                  :key="index"
                  :to="menu.to"
                  :icon="menu.icon"
                  :text="menu.label"
                />
              </ListGroupSection>
            </template>
            <AppDivider />
            <ListGroupSection>
              <ListGroupTitle :text="t('business_solution')" />
              <ListGroupItem
                v-if="!authStore.isMerchant"
                icon="person_apron"
                :text="t('apply_as_merchant')"
                @click="showMerchantRegistration"
              />
              <ListGroupItem
                v-for="(menu, index) in businessSolutionMenu"
                :key="index"
                :to="menu.to"
                :icon="menu.icon"
                :text="menu.label"
              />
            </ListGroupSection>
            <AppDivider />
            <ListGroupSection>
              <ListGroupTitle :text="t('company')" />
              <ListGroupItem
                v-for="(menu, index) in companyMenu"
                :key="index"
                :to="menu.to"
                :icon="menu.icon"
                :text="menu.label"
                :href="menu.href"
              />
            </ListGroupSection>
            <AppDivider />
            <ListGroupSection>
              <ListGroupTitle :text="t('legal')" />
              <ListGroupItem
                v-for="(menu, index) in legalMenu"
                :key="index"
                :to="menu.to"
                :icon="menu.icon"
                :text="menu.label"
              />
            </ListGroupSection>
          </ListGroup>
        </div>

        <div>
          <AppButton
            v-if="authStore.isAuthenticated"
            color="red"
            outline
            :icon="{ name: 'logout' }"
            :text="t('logout')"
            block
            @click="authStore.logout"
          />
          <AppButton
            v-else
            color="blue"
            :icon="{ name: 'login' }"
            :text="t('login')"
            block
            @click="login"
          />
        </div>
      </div>
    </AppBlock>
  </AppDrawer>
</template>
