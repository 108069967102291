import event from '@/event-tracker/google/event'

class DataBuilder {
  public addToCartData = (product: Product, quantity: number, guest: boolean) => {
    return {
      event: guest ? event.GUEST_ADD_TO_CART : event.ADD_TO_CART,
      ecommerce: this.ecommerceData([product], product.price * quantity)
    }
  }

  public addToWishlistData = (product: Product) => {
    return {
      event: event.ADD_TO_WISHLIST,
      ecommerce: this.ecommerceData([product])
    }
  }

  public beginCheckoutData = (products: Product[], value: number) => {
    return {
      event: event.BEGIN_CHECKOUT,
      ecommerce: this.ecommerceData(products, value)
    }
  }

  public purchaseData = (order: Order) => {
    const products = order.items
      ?.filter((item) => item.product !== undefined)
      .map((item) => item.product as Product)

    if (products && order.item_subtotal) {
      return {
        event: event.PURCHASE,
        ecommerce: {
          transaction_id: order.number,
          shipping: order.shipping_cost,
          currency: 'IDR',
          value: order.item_subtotal,
          items: order.items?.map((item) => ({
            item_id: item.product?.id,
            item_name: item.product?.name,
            price: item.product?.price,
            quantity: item.quantity
          }))
        }
      }
    }
  }

  public removeFromCartData = (products: Product[], value: number) => {
    return {
      event: event.REMOVE_FROM_CART,
      ecommerce: this.ecommerceData(products, value)
    }
  }

  public searchData = (searchTerm: string) => {
    return {
      event: event.SEARCH,
      search_term: searchTerm
    }
  }

  public shareData = (product: Product) => {
    return {
      event: event.SHARE,
      method: 'internal_share_button',
      content_type: 'page',
      item_id: product.id
    }
  }

  public signUpData = () => {
    return {
      event: event.SIGN_UP
    }
  }

  public viewItemData = (product: Product) => {
    return {
      event: event.VIEW_ITEM,
      ecommerce: this.ecommerceData([product])
    }
  }

  public initiateInquiryData = () => {
    return {
      event: event.INITIATE_INQUIRY
    }
  }

  public inquirySubmissionData = () => {
    return {
      event: event.INQUIRY_SUBMISSION
    }
  }

  public contactData = () => {
    return {
      event: event.CONTACT
    }
  }

  private ecommerceData = (products: Product[], value?: number) => {
    return {
      currency: 'IDR',
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        price: product.price
      })),
      value: value ?? products.reduce((total, product) => total + product.price, 0)
    }
  }
}

export default DataBuilder
