import { useBreakpoints } from '@vueuse/core'
import { preset } from 'lilasia-ui'
import { useNumber } from './number'

export const useScreenSize = () => {
  const { convertToInteger } = useNumber()

  const breakpoints = useBreakpoints({
    potato: convertToInteger(preset.theme.screens.xs.max),
    mobile: convertToInteger(preset.theme.screens.sm),
    tablet: convertToInteger(preset.theme.screens.md),
    desktop: convertToInteger(preset.theme.screens.lg)
  })

  const isPotato = breakpoints.isSmallerOrEqual('potato')
  const isMobile = breakpoints.isSmaller('tablet')
  const isTablet = breakpoints.isGreater('mobile') && breakpoints.isSmaller('desktop')
  const isDesktop = breakpoints.isGreaterOrEqual('desktop')

  return {
    isPotato,
    isMobile,
    isTablet,
    isDesktop
  }
}
