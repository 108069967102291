import { defineStore } from 'pinia'
import { ref } from 'vue'

const useProductStore = defineStore('product', () => {
  const selectedProduct = ref<Product | null>(null)
  const showPurchaseOptions = ref(false)
  const loading = ref(true)
  const addedToCartStatus = ref(false)
  const showQuickAction = ref(true)
  const activeImageIndex = ref(0)
  const redirectedFromGallery = ref(false)

  return {
    selectedProduct,
    showPurchaseOptions,
    loading,
    addedToCartStatus,
    showQuickAction,
    activeImageIndex,
    redirectedFromGallery
  }
})

export default useProductStore
