import { defineStore } from 'pinia'
import { ref } from 'vue'

const useFetchStore = defineStore('fetch', () => {
  const error = ref<FetchError>()

  return {
    error
  }
})

export default useFetchStore
