<script lang="ts" setup>
import { useScreenSize } from '@/composables/screen-size'
import Icon from 'lilasia-icons'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    large?: boolean
    roundedClass?: string
  }>(),
  {
    roundedClass: 'rounded-4'
  }
)

const { isMobile, isDesktop } = useScreenSize()
const { t } = useI18n()

const height = computed(() => {
  if (props.large) {
    return isDesktop ? 'h-48' : 'h-36'
  }

  return 'h-24'
})

const iconSize = computed(() => {
  if (props.large) {
    return isDesktop ? 24 : 20
  }

  return isMobile ? 12 : 16
})

const textSize = computed(() => {
  if (props.large) {
    return isDesktop ? 'text-20 leading-26' : 'text-16 leading-20'
  }

  return 'text-10 md:text-12 '
})
</script>

<template>
  <div
    class="relative flex items-center"
    :class="height"
  >
    <div class="absolute inset-[0]">
      <img
        src="/images/common/official-merchant-badge-image.png"
        alt="Official Merchant"
        width="141"
        height="24"
        class="h-full w-full object-cover brightness-90"
        :class="roundedClass"
      />
    </div>
    <div class="z-10 flex items-center gap-4 px-8 text-white lg:px-16">
      <Icon
        name="verified"
        :size="iconSize"
      />
      <span
        class="font-600"
        :class="textSize"
        >{{ t('official_merchant') }}</span
      >
    </div>
  </div>
</template>
