class DataBuilder {
  public addToCartData = (product: Product, quantity: number) => {
    return {
      currency: 'IDR',
      content_ids: [product.id],
      content_type: 'product',
      contents: [
        {
          id: product.id,
          name: product.name,
          quantity
        }
      ],
      value: product.price
    }
  }

  public addToWishlistData = (product: Product) => {
    return {
      currency: 'IDR',
      content_ids: [product.id],
      content_type: 'product',
      contents: [
        {
          id: product.id,
          name: product.name,
          quantity: 1
        }
      ],
      value: product.price
    }
  }

  public beginCheckoutData = (products: Product[], value: number) => {
    return {
      currency: 'IDR',
      content_ids: products.map((product) => product.id),
      content_type: 'product',
      contents: products.map((product) => ({
        id: product.id,
        name: product.name,
        quantity: 1
      })),
      value
    }
  }

  public purchaseData = (order: Order) => {
    const products = order.items
      ?.filter((item) => item.product !== undefined)
      .map((item) => item.product as Product)

    if (products && order.item_subtotal) {
      return {
        currency: 'IDR',
        transaction_id: order.number,
        shipping: order.shipping_cost,
        items: order.items?.map((item) => ({
          id: item.product?.id,
          name: item.product?.name,
          price: item.product?.price,
          quantity: item.quantity
        })),
        value: order.item_subtotal
      }
    }
  }

  public removeFromCartData = () => {
    return {}
  }

  public searchData = (searchTerm: string) => {
    return {
      search_string: searchTerm
    }
  }

  public shareData = () => {
    return {}
  }

  public signUpData = () => {
    return {}
  }

  public viewItemData = (product: Product) => {
    return {
      currency: 'IDR',
      content_ids: [product.id],
      content_type: 'product',
      contents: [
        {
          id: product.id,
          name: product.name,
          quantity: 1
        }
      ],
      value: product.price
    }
  }

  public initiateInquiryData = () => {
    return {}
  }

  public inquirySubmissionData = () => {
    return {}
  }

  public contactData = () => {
    return {}
  }
}

export default DataBuilder
