import useAppStore from '@/stores/app'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const useSearchStore = defineStore('search', () => {
  const { t } = useI18n()
  const router = useRouter()

  const appStore = useAppStore()

  const sorts = [
    {
      label: t('most_relevant'),
      value: ''
    },
    {
      label: t('price_asc'),
      value: 'price_asc'
    },
    {
      label: t('price_desc'),
      value: 'price_desc'
    },
    {
      label: t('best_sellers'),
      value: 'sold'
    },
    {
      label: t('top_reviews'),
      value: 'rating'
    }
  ]

  const keyword = ref<string>('')
  const loading = ref(true)
  const meta = ref<Meta | null>(null)
  const notFound = ref(false)
  const products = ref<Product[]>([])
  const categories = ref<Category[]>([])
  const minPrice = ref<number>()
  const maxPrice = ref<number>()
  const selectedCategories = ref<string[]>([])
  const listView = ref(false)
  const state = ref<SearchState>(SearchState.Idle)
  const suggestions = ref<string[]>([])
  const trendingWords = ref<Record<number, string>>({})
  const shops = ref<Shop[]>([])

  const recentSearches = useLocalStorage(
    `${appStore.storagePrefix}_recent_searches`,
    [] as RecentSearch[]
  )

  const recentlyViewedProduct = useLocalStorage(
    `${appStore.storagePrefix}product_view`,
    [] as RecentlyViewedProduct[]
  )

  const recentlyViewedMerchant = useLocalStorage(
    `${appStore.storagePrefix}merchant_view`,
    [] as RecentlyViewedMerchant[]
  )

  const addRecentSearch = (keyword: string) => {
    const now = Math.floor(Date.now() / 1000)

    recentSearches.value = recentSearches.value
      .filter((item) => item.keyword)
      .filter((item) => item.keyword !== keyword)

    recentSearches.value.push({
      keyword: keyword,
      timestamp: now
    })

    if (recentSearches.value.length > 6) {
      recentSearches.value.shift()
    }
  }

  const removeRecentSearch = (keyword: string) => {
    recentSearches.value = recentSearches.value.filter((item) => item.keyword !== keyword)
  }

  const clearRecentSearch = () => {
    recentSearches.value = []
  }

  const clearRecentSearchMerchant = () => {
    recentlyViewedMerchant.value = []
  }

  const resetFilter = () => {
    router.push({
      name: 'search',
      query: {
        query: keyword.value
      }
    })
  }

  return {
    categories,
    keyword,
    listView,
    loading,
    maxPrice,
    meta,
    minPrice,
    notFound,
    products,
    recentSearches,
    recentlyViewedProduct,
    recentlyViewedMerchant,
    selectedCategories,
    sorts,
    state,
    suggestions,
    trendingWords,
    shops,
    addRecentSearch,
    removeRecentSearch,
    clearRecentSearch,
    clearRecentSearchMerchant,
    resetFilter
  }
})

export default useSearchStore

export enum SearchState {
  Idle = 'idle',
  Typing = 'typing',
  PasteLink = 'paste-link'
}
