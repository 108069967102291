import Api from './Api'

class SearchSuggestion extends Api {
  protected resource = 'search-suggestions'

  public async get<T>(options = {}) {
    let url = this.resource

    const params = new URLSearchParams(options).toString()

    if (Object.keys(options).length) {
      url += `?${params}`
    }

    const { data, error, response } = await this.fetch(`${url}`).json<T>()

    return this.handleResponse<T>(data.value, error.value, response.value?.status)
  }
}

export default new SearchSuggestion()
