<script setup lang="ts">
import { Transition, TransitionGroup } from 'vue'

const props = defineProps<{
  enterDuration?: number
  group?: boolean
  leaveDuration?: number
}>()

const component = props.group ? TransitionGroup : Transition
</script>

<template>
  <component
    :is="component"
    enter-active-class="transition-opacity duration-200 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-150 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot />
  </component>
</template>
