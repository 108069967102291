<script setup lang="ts">
import SearchSuggestion from '@/api/SearchSuggestion'
import { useScreenSize } from '@/composables/screen-size'
import useInquiryStore from '@/stores/inquiry'
import useSearchStore, { SearchState } from '@/stores/search'
import useSearchSuggestionStore from '@/stores/search-suggestion'
import { watchDebounced } from '@vueuse/core'
import Icon from 'lilasia-icons'
import { AppSection, ButtonLink, useDialog } from 'lilasia-ui'
import { storeToRefs } from 'pinia'
import { computed, onMounted, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import AppImage from '../common/AppImage.vue'
import MakeInquiryModal from '../common/MakeInquiryModal.vue'
import OfficialMerchantBadge from '../common/OfficialMerchantBadge.vue'
import TextOverflowAnywhere from '../common/TextOverflowAnywhere.vue'

const { isDesktop } = useScreenSize()
const { t } = useI18n()
const { showDialog } = useDialog()
const route = useRoute()
const searchStore = useSearchStore()
const searchSuggestionStore = useSearchSuggestionStore()
const inquiryStore = useInquiryStore()

const { state, keyword: search } = storeToRefs(searchStore)
const { activeIndex, maxIndex } = storeToRefs(searchSuggestionStore)

const suggestions = computed(() => {
  return searchStore.suggestions.slice(0, 5)
})

const trendingWords = computed(() => {
  return Object.values(searchStore.trendingWords).slice(0, 5)
})

const shops = computed(() => {
  return searchStore.shops.slice(0, 4)
})

const recentSearches = computed(() => {
  return [...searchStore.recentSearches]
    .filter((a) => a.keyword)
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 6)
})

const recentlyViewedProduct = computed(() => {
  return [...searchStore.recentlyViewedProduct]
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 4)
})

const recentlyViewedMerchant = computed(() => {
  return [...searchStore.recentlyViewedMerchant]
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 4)
})

watchDebounced(
  search,
  (newSearch) => {
    if (isURL(newSearch)) {
      activeIndex.value = !isDesktop ? -1 : 0
      state.value = SearchState.PasteLink
      return
    }

    if (newSearch.length) {
      activeIndex.value = !isDesktop ? -1 : 0
      state.value = SearchState.Typing
      getSearchSuggestions({ term: newSearch })
      return
    }

    if (!newSearch.length) {
      activeIndex.value = -1
      state.value = SearchState.Idle
      return
    }
  },
  { immediate: true, debounce: 500 }
)

watchEffect(() => {
  if (!isDesktop) {
    return
  }

  if (state.value === SearchState.PasteLink) {
    maxIndex.value = 0
  }

  if (state.value === SearchState.Idle) {
    let firstIndex = trendingWords.value.length
    const secondIndex = recentlyViewedMerchant.value.length

    if (recentSearches.value.length) {
      firstIndex = recentSearches.value.length
    }

    maxIndex.value = firstIndex + secondIndex - 1
  }

  if (state.value === SearchState.Typing) {
    const firstIndex = 1
    const secondIndex = suggestions.value.length
    const thirdIndex = shops.value.length

    maxIndex.value = firstIndex + secondIndex + thirdIndex - 1
  }
})

onMounted(() => {
  if (typeof route.query.query === 'string' && route.query.query.length) {
    search.value = route.query.query
    state.value = SearchState.Typing
  }

  getSearchSuggestions(route.query.query ? { term: route.query.query } : {})
})

const getSearchSuggestions = async (query = {}) => {
  try {
    const { suggestions, trending, shops } = await SearchSuggestion.get<SearchSuggestion>({
      ...query
    })

    searchStore.suggestions = suggestions
    searchStore.trendingWords = trending
    searchStore.shops = shops
  } catch (error) {
    const fetchError = error as FetchError
    console.error(fetchError.message)
  }
}

const removeRecentSearch = (keyword: string) => {
  searchStore.removeRecentSearch(keyword)
}

const clearRecentSearch = () => {
  searchStore.clearRecentSearch()
}

const clearRecentSearchMerchant = () => {
  searchStore.clearRecentSearchMerchant()
}

const showInquiryModal = (url?: string) => {
  inquiryStore.url = url
  showDialog(MakeInquiryModal)
}

const highlightText = (text: string, term: string) => {
  if (!term) {
    return text
  }

  // return original text if search term is empty
  const regex = new RegExp(`(${term})`, 'gi')

  // Split the text by the search term
  const parts = text.split(regex)

  // Wrap non-matching parts in <strong> tags
  return parts
    .map((part) => (part.toLowerCase() === term.toLowerCase() ? part : `<strong>${part}</strong>`))
    .join('')
}

const getMerchantIndex = (index: number) => {
  let lastIndex = trendingWords.value.length

  if (recentSearches.value.length) {
    lastIndex = recentSearches.value.length
  }

  return lastIndex + index
}

const isMerchantIndexActive = (index: number) => {
  let lastIndex = trendingWords.value.length

  if (recentSearches.value.length) {
    lastIndex = recentSearches.value.length
  }

  return activeIndex.value === lastIndex + index
}

const getMerchantResultIndex = (index: number) => {
  const lastIndex = 1 + suggestions.value.length

  return lastIndex + index
}

const isMerchantResultIndexActive = (index: number) => {
  const lastIndex = 1 + suggestions.value.length

  return activeIndex.value === lastIndex + index
}

const isURL = (keyword: string) => {
  const expression =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi

  const regex = new RegExp(expression)

  return keyword.match(regex)
}
</script>

<template>
  <div class="pb-16 lg:pt-16">
    <template v-if="state === SearchState.Idle">
      <!-- Recent Searched -->
      <AppSection
        v-if="recentSearches.length"
        fluid
        no-gap
        class="mt-8 space-y-8 lg:mt-0"
      >
        <div
          :class="{
            'flex items-center justify-between gap-16 lg:px-16': true,
            'container mx-auto': !isDesktop
          }"
        >
          <div class="text-20 font-600">Recent Searches</div>
          <ButtonLink
            v-if="recentSearches.length > 1"
            :text="t('clear_all')"
            class="font-600 text-red-100 no-underline hover:text-red-80"
            size="lg"
            tabindex="-1"
            @click.prevent="clearRecentSearch"
          />
        </div>

        <ul>
          <li
            v-for="({ keyword }, index) in recentSearches"
            :key="index"
            :data-lilasia-search="`search-${index}`"
            :data-lilasia-search-to="
              JSON.stringify({
                name: 'search',
                query: { query: keyword }
              })
            "
            :class="{
              'bg-black-5': activeIndex === index
            }"
          >
            <RouterLink
              :to="{ name: 'search', query: { query: keyword } }"
              tabindex="-1"
              class="outline-none"
            >
              <div
                :class="{
                  'flex h-40 items-center justify-between gap-16 transition-colors hover:bg-black-5 lg:px-16': true,
                  'container mx-auto': !isDesktop
                }"
              >
                <div class="flex items-center gap-8">
                  <Icon
                    name="device_reset"
                    size="20"
                  />
                  <TextOverflowAnywhere>
                    <span class="line-clamp-1">{{ keyword }}</span>
                  </TextOverflowAnywhere>
                </div>

                <button
                  type="button"
                  aria-label="Remove keyword"
                  class="py-[10px] pl-24 outline-none"
                  tabindex="-1"
                  @click.prevent="removeRecentSearch(keyword)"
                >
                  <div class="flex items-center">
                    <Icon
                      name="clear"
                      size="20"
                    />
                  </div>
                </button>
              </div>
            </RouterLink>
          </li>
        </ul>
      </AppSection>

      <!-- Trending Search -->
      <AppSection
        v-else-if="trendingWords.length"
        fluid
        no-gap
        class="mt-8 space-y-8 lg:mt-0"
      >
        <div
          :class="{
            'text-20 font-600 lg:px-16': true,
            'container mx-auto': !isDesktop
          }"
        >
          {{ t('trending_search') }}
        </div>

        <ul>
          <li
            v-for="(keyword, index) in trendingWords"
            :key="index"
            :data-lilasia-search="`search-${index}`"
            :data-lilasia-search-to="
              JSON.stringify({
                name: 'search',
                query: { query: keyword }
              })
            "
            :class="{
              'bg-black-5': activeIndex === index
            }"
          >
            <RouterLink
              :to="{ name: 'search', query: { query: keyword } }"
              tabindex="-1"
              class="outline-none"
            >
              <div
                :class="{
                  'flex h-40 items-center justify-between gap-16 transition-colors hover:bg-black-5 lg:px-16': true,
                  'container mx-auto': !isDesktop
                }"
              >
                <div class="flex items-center gap-8">
                  <Icon
                    name="trending_up"
                    size="20"
                  />
                  <TextOverflowAnywhere>
                    <span class="line-clamp-1">{{ keyword }}</span>
                  </TextOverflowAnywhere>
                </div>
              </div>
            </RouterLink>
          </li>
        </ul>
      </AppSection>

      <!-- Viewed Products -->
      <AppSection
        v-if="recentlyViewedProduct.length"
        no-gap
        fluid
        :class="{
          'mt-16 space-y-8': true,
          'container mx-auto': !isDesktop
        }"
      >
        <div class="text-20 font-600 lg:px-16">Viewed Products</div>

        <div class="hide-scrollbar flex flex-nowrap gap-8 overflow-x-auto py-4 lg:px-16">
          <RouterLink
            v-for="(view, index) in recentlyViewedProduct"
            :key="index"
            :to="{
              name: 'products.show',
              params: { id: view.product.id, slug: view.product.slug }
            }"
            tabindex="-1"
            class="rounded-8 outline-none transition hover:ring-2 hover:ring-black-20 hover:ring-offset-2"
          >
            <AppImage
              v-if="view.product.thumbnail_url"
              :src="view.product.thumbnail_url"
              :alt="view.product.name"
              :width="100"
              :height="100"
              class="size-[100px] min-w-[100px] rounded-[10px] border border-black-10 object-cover"
            />
          </RouterLink>
        </div>
      </AppSection>

      <!-- Viewed Merchants -->
      <AppSection
        v-if="recentlyViewedMerchant.length"
        no-gap
        fluid
        class="mt-16 space-y-16"
      >
        <div
          :class="{
            'flex items-center justify-between gap-16 lg:px-16': true,
            'container mx-auto': !isDesktop
          }"
        >
          <div class="text-20 font-600">Viewed Merchants</div>
          <ButtonLink
            :text="t('clear_all')"
            class="text-nowrap font-600 text-red-100 no-underline hover:text-red-80"
            size="lg"
            tabindex="-1"
            @click.prevent="clearRecentSearchMerchant"
          />
        </div>

        <div class="flex flex-col">
          <RouterLink
            v-for="(view, index) in recentlyViewedMerchant"
            :key="index"
            :data-lilasia-search="`search-${getMerchantIndex(index)}`"
            :data-lilasia-search-to="
              JSON.stringify({
                name: 'shops.show',
                params: { slug: view.shop.slug }
              })
            "
            :to="{ name: 'shops.show', params: { slug: view.shop.slug } }"
            :class="{
              'outline-none transition-colors hover:bg-black-5': true,
              'bg-black-5': isMerchantIndexActive(index)
            }"
            tabindex="-1"
          >
            <div
              :class="{
                'flex items-center gap-8 py-[10px] lg:px-16': true,
                'container mx-auto': !isDesktop
              }"
            >
              <AppImage
                v-if="view.shop.logo_thumbnail_url"
                :src="view.shop.logo_thumbnail_url"
                :alt="view.shop.name"
                :width="56"
                :height="56"
                class="size-56 rounded-8 border border-black-10 bg-white object-scale-down"
              />
              <div class="space-y-8">
                <TextOverflowAnywhere>
                  <div class="line-clamp-1">{{ view.shop.display_name }}</div>
                </TextOverflowAnywhere>
                <OfficialMerchantBadge class="w-fit" />
              </div>
            </div>
          </RouterLink>
        </div>
      </AppSection>
    </template>

    <template v-if="state === SearchState.Typing">
      <!-- Search Suggestions -->
      <AppSection
        fluid
        no-gap
        class="mt-8 space-y-8 lg:mt-0"
      >
        <ul>
          <li
            data-lilasia-search="search-0"
            :data-lilasia-search-to="
              JSON.stringify({
                name: 'search',
                query: { query: search }
              })
            "
            :class="{ 'active bg-black-5': activeIndex === 0 }"
          >
            <RouterLink
              :to="{ name: 'search', query: { query: search } }"
              tabindex="-1"
              class="outline-none"
            >
              <div
                :class="{
                  'flex h-40 items-center justify-between gap-16 transition-colors hover:bg-black-5 lg:px-16': true,
                  'container mx-auto': !isDesktop
                }"
              >
                <div class="flex items-center gap-8">
                  <Icon
                    name="search"
                    size="20"
                  />
                  <TextOverflowAnywhere>
                    <span class="line-clamp-1">{{ search }}</span>
                  </TextOverflowAnywhere>
                </div>
              </div>
            </RouterLink>
          </li>

          <li
            v-for="(keyword, index) in suggestions"
            :key="index"
            :data-lilasia-search="`search-${index + 1}`"
            :data-lilasia-search-to="
              JSON.stringify({
                name: 'search',
                query: { query: keyword }
              })
            "
            :class="{
              'bg-black-5': activeIndex === index + 1
            }"
          >
            <RouterLink
              :to="{ name: 'search', query: { query: keyword } }"
              tabindex="-1"
              class="outline-none"
            >
              <div
                :class="{
                  'flex h-40 items-center justify-between gap-16 transition-colors hover:bg-black-5 lg:px-16': true,
                  'container mx-auto': !isDesktop
                }"
              >
                <div class="flex items-center gap-8">
                  <Icon
                    name="search"
                    size="20"
                  />
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    class="line-clamp-1"
                    v-html="highlightText(keyword, search)"
                  ></span>
                </div>
              </div>
            </RouterLink>
          </li>
        </ul>
      </AppSection>

      <!-- Shops Suggestions -->
      <AppSection
        v-if="shops.length"
        no-gap
        fluid
        class="mt-16 space-y-16"
      >
        <div
          :class="{
            'flex items-center justify-between gap-16 lg:px-16': true,
            'container mx-auto': !isDesktop
          }"
        >
          <div class="text-20 font-600">Merchants</div>
        </div>

        <div class="flex flex-col">
          <RouterLink
            v-for="(shop, index) in shops"
            :key="index"
            :data-lilasia-search="`search-${getMerchantResultIndex(index)}`"
            :data-lilasia-search-to="
              JSON.stringify({
                name: 'shops.show',
                params: { slug: shop.slug }
              })
            "
            :to="{ name: 'shops.show', params: { slug: shop.slug } }"
            :class="{
              'outline-none transition-colors hover:bg-black-5': true,
              'bg-black-5': isMerchantResultIndexActive(index)
            }"
            tabindex="-1"
          >
            <div
              :class="{
                'flex items-center gap-8 py-[10px] lg:px-16': true,
                'container mx-auto': !isDesktop
              }"
            >
              <AppImage
                v-if="shop.logo_thumbnail_url"
                :src="shop.logo_thumbnail_url"
                :alt="shop.name"
                :width="56"
                :height="56"
                class="size-56 rounded-8 border border-black-10 bg-white object-scale-down"
              />
              <div class="space-y-8">
                <TextOverflowAnywhere>
                  <div class="line-clamp-1">{{ shop.display_name }}</div>
                </TextOverflowAnywhere>
                <OfficialMerchantBadge class="w-fit" />
              </div>
            </div>
          </RouterLink>
        </div>
      </AppSection>
    </template>

    <template v-if="state === SearchState.PasteLink">
      <!-- Make Inquiry -->
      <AppSection
        fluid
        no-gap
        class="mt-8 space-y-8 lg:mt-0"
      >
        <div
          :class="{
            'flex items-center justify-between gap-16 lg:px-16': true,
            'container mx-auto': !isDesktop
          }"
        >
          <div class="text-20 font-600">You've entered a link</div>
        </div>
        <ul>
          <li
            data-lilasia-search="search-0"
            :data-lilasia-inquiry="search"
            :class="{ 'active bg-black-5': activeIndex === 0 }"
          >
            <button
              tabindex="-1"
              class="w-full outline-none"
              @click="showInquiryModal(search)"
            >
              <div
                :class="{
                  'flex items-center justify-between gap-16 py-8 transition-colors hover:bg-black-5 lg:px-16': true,
                  'container mx-auto': !isDesktop
                }"
              >
                <div class="flex items-center gap-8 text-start">
                  <Icon
                    name="inventory"
                    size="20"
                  />
                  <span>Click here to inquire about this product.</span>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </AppSection>
    </template>
  </div>
</template>
