import { ref, watch } from 'vue'
import { z, type ZodType, type ZodTypeDef } from 'zod'

export function useValidator<T extends ZodType<any, ZodTypeDef, any>>(
  schema: T,
  data?: Partial<z.infer<T>>,
  options?: { eager?: boolean }
) {
  type SchemaType = z.infer<T>

  const formData = ref({ ...data } as SchemaType)
  const formError = ref<any>({})

  const validate = () => {
    try {
      schema.parse(formData.value)
      formError.value = {} as Partial<Record<keyof SchemaType, string | Array<string>>>

      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        formError.value = Object.fromEntries(
          Object.entries(error.flatten().fieldErrors).map(([key, value]) => [
            key,
            Array.isArray(value) ? value.join(', ') : value
          ])
        )
      }

      return false
    }
  }

  if (options && options.eager) {
    watch(formData, () => validate(), { deep: true })
  }

  return {
    formData,
    formError,
    validate
  }
}
