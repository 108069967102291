export default class FetchError extends Error {
  public code: number | null
  public errors: Record<string, string[]> | null

  constructor(
    message: string,
    errors: Record<string, string[]> | null = null,
    code: number | null = null
  ) {
    super(message)
    this.code = code
    this.errors = errors
  }
}
