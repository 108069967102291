<script setup lang="ts">
import { useMenu } from '@/composables/menu'
import useAuthStore from '@/stores/auth'
import Icon from 'lilasia-icons'
import { AppButton, AppDivider, AppDrawer } from 'lilasia-ui'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate } from 'vue-router'
import CloseButton from '../CloseButton.vue'
import ListGroup from '../list-group/ListGroup.vue'
import ListGroupItem from '../list-group/ListGroupItem.vue'
import ListGroupSection from '../list-group/ListGroupSection.vue'
import ListGroupTitle from '../list-group/ListGroupTitle.vue'

const { merchantMenu } = useMenu()
const { t } = useI18n()
const authStore = useAuthStore()

const showMerchantMenu = ref(false)

onBeforeRouteUpdate(() => {
  showMerchantMenu.value = false
})

const logout = () => {
  showMerchantMenu.value = false
  authStore.logout()
}
</script>

<template>
  <div class="inline-flex lg:hidden">
    <div
      role="button"
      @click="showMerchantMenu = true"
    >
      <Icon
        name="menu"
        size="32"
      />
    </div>

    <Teleport to="body">
      <AppDrawer
        v-model="showMerchantMenu"
        position="right"
        class="w-3/4 overflow-y-auto bg-white md:w-1/2"
        backdrop
      >
        <div class="flex h-dvh flex-col justify-between gap-y-16 p-16">
          <div>
            <div class="flex items-center justify-between pb-24 leading-38">
              <CloseButton @click="showMerchantMenu = false" />
              <div class="flex-1 text-center font-600 text-black-100">{{ t('merchant_menu') }}</div>
            </div>

            <ListGroup>
              <ListGroupSection>
                <ListGroupTitle text="LILASIA.ID" />
                <ListGroupItem
                  :to="{ name: 'home' }"
                  icon="undo"
                  text="Back to Lilasia"
                />
              </ListGroupSection>
              <AppDivider />
              <ListGroupSection>
                <ListGroupTitle :text="t('merchant_centre')" />
                <ListGroupItem
                  v-for="(menu, index) in merchantMenu"
                  :key="index"
                  :to="menu.to"
                  :icon="menu.icon"
                  :text="menu.label"
                />
              </ListGroupSection>
            </ListGroup>
          </div>

          <div>
            <AppButton
              color="red"
              outline
              :icon="{ name: 'logout' }"
              :text="t('logout')"
              block
              @click="logout"
            />
          </div>
        </div>
      </AppDrawer>
    </Teleport>
  </div>
</template>
