import { useFetch } from '@/composables/fetch'
import FetchError from '@/FetchError'

const { fetch } = useFetch()

export default abstract class Api {
  protected fetch = fetch

  protected resource: string = ''

  public async get<T>(options = {}, path: string | null = null) {
    let url = this.resource

    if (path) {
      url += `/${path}`
    }

    const params = new URLSearchParams(options).toString()

    if (Object.keys(options).length) {
      url += `?${params}`
    }

    const { data, error, response } = await this.fetch(url).json<T>()

    return this.handleResponse<T>(data.value, error.value, response.value?.status)
  }

  public async paginate<T>(options = {}, path: string | null = null) {
    options = { ...options, paginate: true }

    return await this.get<T>(options, path)
  }

  public async show<T>(resourceId: number) {
    const { data, error, response } = await this.fetch(`${this.resource}/${resourceId}`).json<T>()

    return this.handleResponse<T>(data.value, error.value, response.value?.status)
  }

  protected handleResponse<T>(
    data: T | null,
    error: { errors: Record<string, string[]> | null; message: string } | null,
    code?: number | null
  ) {
    if (error?.message) {
      throw new FetchError(error.message, error.errors, code)
    }

    return <T>data
  }
}
