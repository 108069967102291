import { defineStore } from 'pinia'
import { ref } from 'vue'

const useInquiryStore = defineStore('inquiry', () => {
  const url = ref<string>()

  return {
    url
  }
})

export default useInquiryStore
