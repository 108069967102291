<script setup lang="ts">
import Merchant from '@/api/Merchant'
import AppFooter from '@/components/common/AppFooter.vue'
import SupportLink from '@/components/common/SupportLink.vue'
import MerchantNavbar from '@/components/common/merchant/MerchantNavbar.vue'
import { useMenu } from '@/composables/menu'
import { useScreenSize } from '@/composables/screen-size'
import useMerchantStore from '@/stores/merchant'
import Icon from 'lilasia-icons'
import { AppBlock, AppDialog, AppSection, AppToast, useToast } from 'lilasia-ui'
import { onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { isDesktop } = useScreenSize()
const { merchantMenu } = useMenu()
const { t } = useI18n()
const { toastError } = useToast()
const merchantStore = useMerchantStore()

watch(
  () => merchantStore.refetch,
  async () => {
    await fetchMerchant()
    merchantStore.refetch = false
  }
)

onMounted(() => {
  fetchMerchant()
})

const fetchMerchant = async () => {
  try {
    merchantStore.shop = await Merchant.get()
  } catch (error) {
    const fetchError = error as FetchError
    toastError(fetchError.message)
  }
}
</script>

<template>
  <div>
    <MerchantNavbar />

    <main class="min-h-screen">
      <AppSection
        :fluid="merchantStore.fluidSection"
        :no-gap="merchantStore.fluidSection"
      >
        <div class="flex flex-col gap-24">
          <AppBlock
            v-if="isDesktop"
            bordered
            :rounded="8"
          >
            <div class="space-y-16 px-24 py-16">
              <div class="flex items-center gap-8">
                <Icon
                  name="storefront"
                  size="24"
                />
                <div class="text-20 font-600">{{ t('merchant_centre') }}</div>
              </div>

              <div class="flex items-center gap-16 border-b border-b-black-10">
                <RouterLink
                  v-for="(item, index) in merchantMenu"
                  :key="index"
                  :to="item.to"
                  class="h-[38px] px-4 text-14 font-500 leading-20 text-black-60 transition-colors hover:text-blue-100"
                  active-class="border-b-2 border-blue-100 text-blue-100"
                >
                  {{ item.label }}
                </RouterLink>
              </div>
            </div>
          </AppBlock>

          <RouterView />
        </div>
      </AppSection>
    </main>

    <AppFooter />

    <Teleport to="body">
      <AppDialog />
      <AppToast />
      <SupportLink :merchant="true" />
    </Teleport>
  </div>
</template>
