import useAppStore from '@/stores/app'
import { useTracker } from './tracker'

export const useContact = () => {
  const { trackContact } = useTracker()
  const appStore = useAppStore()

  const openPhone = async () => {
    trackContact()
    window.open(`tel:${appStore.support.phone}`)
  }

  const openMail = async () => {
    trackContact()
    window.open(`mailto:${appStore.support.email}`)
  }

  const openWhatsApp = async () => {
    trackContact()
    window.open(appStore.whatsAppLink, '_blank')
  }

  return { openMail, openPhone, openWhatsApp }
}
