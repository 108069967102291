<script setup lang="ts">
import SelectCurrency from '@/components/common/SelectCurrency.vue'
import useAppStore from '@/stores/app'
import useAuthStore from '@/stores/auth'
import useCartStore from '@/stores/cart'
import useSearchStore from '@/stores/search'
import useSearchSuggestionStore from '@/stores/search-suggestion'
import Icon from 'lilasia-icons'
import {
  AppButton,
  AppDropdown,
  AppIndicators,
  AppNavbar,
  DropdownItem,
  DropdownItemGroup,
  useDialog
} from 'lilasia-ui'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, onBeforeRouteUpdate, useRoute } from 'vue-router'
import SearchBar from '../search/SearchBar.vue'

const { t } = useI18n()
const { showDialog } = useDialog()
const route = useRoute()

const appStore = useAppStore()
const authStore = useAuthStore()
const searchStore = useSearchStore()
const cartStore = useCartStore()
const searchSuggestionStore = useSearchSuggestionStore()

const { showMobileMenu } = storeToRefs(appStore)
const { isOpenOnNavbar } = storeToRefs(searchSuggestionStore)

const isCheckoutPage = computed(() => {
  return route.name === 'checkout'
})

onMounted(() => {
  if ('search' === route.name && route.query.query) {
    searchStore.keyword = String(route.query.query)
    return
  }

  searchStore.keyword = ''
})

onBeforeRouteUpdate((to) => {
  if ('search' === to.name && to.query.query) {
    searchStore.keyword = String(to.query.query)
    return
  }

  searchStore.keyword = ''
})

const openCartModal = () => {
  showDialog(
    defineAsyncComponent(() => import('@/components/common/CartModal.vue')),
    { position: 'top-right' }
  )
}
</script>

<template>
  <AppNavbar
    v-show="appStore.showNavbar"
    class="flex h-56 items-center bg-white shadow md:h-[72px]"
    sticky
  >
    <nav class="flex items-center justify-between gap-8 md:gap-32">
      <div class="flex items-center justify-start gap-32 md:min-w-[103px] lg:min-w-[110px]">
        <div class="flex items-center gap-16">
          <RouterLink :to="{ name: 'home' }">
            <!-- Desktop/Tablet Logo -->
            <img
              class="hidden h-24 md:block"
              src="/images/common/lilasia-logo-black.svg"
              :alt="appStore.name"
              width="100"
              height="25.81"
            />

            <!-- Mobile Logo -->
            <img
              class="mr-12 size-32 md:hidden"
              src="/images/common/lilasia-logo.svg"
              :alt="appStore.name"
              width="32"
              height="32"
            />
          </RouterLink>

          <!-- Checkout Label -->
          <div
            v-if="isCheckoutPage"
            class="border-l border-l-black-10 pl-16 text-24 font-600 leading-32 text-black-60"
          >
            {{ t('checkout') }}
          </div>
        </div>
      </div>

      <!-- Search Bar -->
      <SearchBar
        v-if="!isCheckoutPage"
        v-model:suggestions="isOpenOnNavbar"
      />

      <div
        class="z-[1] ms-4 flex items-center justify-end sm:ms-8 sm:gap-12 md:ms-16 md:gap-24"
        :class="{ 'md:ms-[0]': !authStore.isAuthenticated }"
      >
        <!-- Shopping Cart -->
        <AppIndicators
          v-if="authStore.isAuthenticated && !isCheckoutPage"
          :label="cartStore.itemCount.toString()"
          :hide="cartStore.itemCount < 1"
          round
        >
          <AppButton
            color="light"
            :icon="{ name: 'local_mall', size: 28 }"
            icon-class="text-black-80"
            @click="openCartModal"
          />
        </AppIndicators>

        <!-- Currency Button -->
        <AppButton
          :class="isCheckoutPage ? '' : 'hidden lg:inline-block'"
          color="light"
          @click="showDialog(SelectCurrency)"
        >
          <div class="flex items-center gap-8 pe-12">
            <img
              :src="String(appStore.selectedRate.flag_url).replace('flag', 'icon')"
              :alt="`selected-${appStore.selectedRate.currency}-icon`"
              width="18"
              height="18"
            />
            <span class="text-16 font-600 text-black-80">{{ appStore.currency.selected }}</span>
          </div>
        </AppButton>

        <!-- User and Login Button -->
        <template v-if="!isCheckoutPage">
          <AppDropdown
            v-if="authStore.isAuthenticated"
            class="hidden lg:block"
            menu-position="right"
          >
            <template #button>
              <AppButton
                color="light"
                :text="String(authStore.user.name)"
                icon-right="keyboard_arrow_down"
              />
            </template>
            <DropdownItemGroup>
              <RouterLink :to="{ name: 'account.order-history' }">
                <DropdownItem
                  icon="local_mall"
                  text="Order History"
                />
              </RouterLink>
              <RouterLink :to="{ name: 'account.wishlist' }">
                <DropdownItem
                  icon="favorite"
                  text="Wishlist"
                />
              </RouterLink>
              <RouterLink :to="{ name: 'account.edit-profile' }">
                <DropdownItem
                  icon="account_circle"
                  text="Account"
                />
              </RouterLink>
            </DropdownItemGroup>

            <DropdownItemGroup v-if="authStore.isMerchant">
              <RouterLink :to="{ name: 'merchant.centre' }">
                <DropdownItem
                  icon="storefront"
                  text="Merchant Centre"
                />
              </RouterLink>
            </DropdownItemGroup>

            <DropdownItemGroup>
              <DropdownItem
                icon="logout"
                text="Logout"
                active-color="red"
                @click="authStore.logout"
              />
            </DropdownItemGroup>
          </AppDropdown>

          <AppButton
            v-else
            class="hidden lg:inline-flex"
            color="blue"
            icon="logout"
            text="Login or Sign Up"
            @click="authStore.showAuthModal()"
          />
        </template>

        <!-- Hamburger Button -->

        <div
          v-if="!isCheckoutPage"
          class="-mr-4 block lg:hidden"
        >
          <div
            class="text-black-80 lg:hidden"
            role="button"
            @click="showMobileMenu = true"
          >
            <Icon
              name="menu"
              size="32"
            />
          </div>
        </div>
      </div>
    </nav>
  </AppNavbar>
</template>
