<script setup lang="ts">
import MakeInquiryModal from '@/components/common/MakeInquiryModal.vue'
import { useContact } from '@/composables/contact'
import { useScreenSize } from '@/composables/screen-size'
import useProductStore from '@/stores/product'
import Icon from 'lilasia-icons'
import { AppButton, useDialog } from 'lilasia-ui'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'

const { openWhatsApp } = useContact()
const { isMobile } = useScreenSize()
const { showDialog } = useDialog()
const { t } = useI18n()
const route = useRoute()

const productStore = useProductStore()

const showWindow = ref(false)
const iconName = ref('support_agent')
const scrollY = ref(0)
const prevScrollY = ref(0)
const isInitialLoad = ref(true)
const disableFlipAnimation = ref(true)

defineProps<{
  merchant?: boolean
}>()
const isDetailPage = computed(() => route.name === 'products.show')

const marginBottom = computed(() => {
  if (isDetailPage.value && productStore.showQuickAction && isMobile) {
    return '76px'
  }
  return ''
})

const isBouncing = computed(() => {
  return window.innerWidth <= 767 && isDetailPage.value && !marginBottom.value
})

onMounted(() => {
  window.addEventListener('scroll', handleScroll)

  if (isInitialLoad.value) {
    isInitialLoad.value = false
  } else {
    disableFlipAnimation.value = false
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

onBeforeRouteUpdate(() => {
  showWindow.value = false
  iconName.value = 'support_agent'
})

const windowToggle = () => {
  iconName.value = showWindow.value ? 'support_agent' : 'close'
  showWindow.value = !showWindow.value
  disableFlipAnimation.value = false
}

const handleScroll = () => {
  prevScrollY.value = scrollY.value
  scrollY.value = window.scrollY

  if (scrollY.value < prevScrollY.value) {
    // Scrolling up
    disableFlipAnimation.value = true
  } else {
    // Scrolling down
    disableFlipAnimation.value = true
  }
}

const showMakeInquiryModal = () => {
  showDialog(MakeInquiryModal)
}
</script>

<template>
  <div>
    <div
      class="container-supporLink fixed bottom-40 right-40 z-20 text-16"
      :style="{ marginBottom: marginBottom }"
    >
      <div class="flex flex-col gap-16">
        <transition name="fadeLink">
          <div
            v-if="showWindow"
            class="window relative mb-4 flex h-auto max-w-[340px] flex-col gap-8 rounded-8 bg-white p-12 shadow-lg xs:max-w-[328px]"
            :class="{ 'fadeLink-in-bottom': showWindow }"
          >
            <div class="text-14">
              {{ t('support_link') }}
            </div>
            <div class="mt-4 flex gap-8">
              <AppButton
                color="green"
                class="flex w-full justify-center text-center align-middle"
                @click="openWhatsApp"
              >
                <div class="flex flex-row justify-center gap-4 text-center align-middle">
                  <img
                    src="/images/common/social-media-icons/whatsapp-white.svg"
                    alt="whatsApp-icon"
                    class="w-20"
                  />
                  <div class="xs:text-14">{{ t('chat_with_us') }}</div>
                </div>
              </AppButton>

              <AppButton
                v-if="!merchant"
                color="blue"
                icon="inventory"
                class="w-full"
                @click="showMakeInquiryModal"
              >
                <div class="xs:text-14">{{ t('make_inquiry') }}</div>
              </AppButton>
            </div>
          </div>
        </transition>

        <AppButton
          class="transition-colors-link-support flex !h-[56px] w-[56px] justify-center self-end rounded-9999 border !border-white shadow-lg"
          :class="{
            '!bg-blue text-white hover:bg-blue-80': !showWindow,
            'bg-white text-blue': showWindow,
            'bounce-effect': isBouncing,
            'flip-in': showWindow && !disableFlipAnimation,
            'flip-out': !showWindow && !disableFlipAnimation
          }"
          @click="windowToggle"
        >
          <Icon
            :name="iconName"
            :size="34"
            class="icon-chat icon-transition"
          />
        </AppButton>
      </div>
    </div>
  </div>
</template>
