import { defineStore } from 'pinia'
import { ref } from 'vue'

const useSearchSuggestionStore = defineStore('search-suggestion', () => {
  const activeIndex = ref(-1)
  const maxIndex = ref(-1)
  const isOpenOnHeroSection = ref(false)
  const isOpenOnNavbar = ref(false)

  return {
    activeIndex,
    maxIndex,
    isOpenOnHeroSection,
    isOpenOnNavbar
  }
})

export default useSearchSuggestionStore
